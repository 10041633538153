import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Select from 'react-select';
import schools from '../Schools';
import haversine from 'haversine-distance';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const getUniqueValues = (arr, key) => {
  const uniqueValues = new Set(arr.map(item => item[key]));
  return ['Any', ...Array.from(uniqueValues)];
};

const fetchZipCodeCoordinates = async (zipCode) => {
  try {
    const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
    const data = await response.json();
    const { places } = data;
    if (places && places.length > 0) {
      const { latitude, longitude } = places[0];
      return [parseFloat(latitude), parseFloat(longitude)];
    }
    return null;
  } catch (error) {
    console.error('Error fetching zip code coordinates:', error);
    return null;
  }
};

const filterSchools = (criteria, schools, zipCodeCoordinates, searchDistance) => {
  return schools.filter(school => {
    return Object.keys(criteria).every(key => {
      if (Array.isArray(criteria[key])) {
        if (criteria[key].includes('Any') || criteria[key].length === 0) return true;
        return criteria[key].some(value => (Array.isArray(school[key]) ? school[key].includes(value) : school[key] === value));
      } else if (key === 'grades') {
        if (criteria[key].length === 0) return true; // Show all schools if no grades are selected
        return criteria[key].some(grade => school.grades.includes(grade));
      } else if (key === 'zipCode' && zipCodeCoordinates) {
        if (criteria[key] === '') return true; // Show all schools if no zip code is entered
        const latitude = school.coordinates[0];
        const longitude = school.coordinates[1];
        const a = { latitude, longitude };
        const b = { latitude: zipCodeCoordinates[0], longitude: zipCodeCoordinates[1] };
        return haversine(a, b) < searchDistance * 1609.34; // Convert miles to meters
      }
      return criteria[key] === '' || (Array.isArray(school[key]) ? school[key].includes(criteria[key]) : school[key] === criteria[key]);
    });
  });
};

const ButtonGroup = ({ options, selectedOption, onSelect, label }) => {
  return (
    <div className="mb-2">
      <label className="block text-gray-700 text-sm font-semibold">{label}</label>
      <div className="flex flex-wrap mt-1">
        {options.map(option => (
          <button
            key={option}
            className={`px-3 py-1 m-1 rounded text-xs ${selectedOption.includes(option) ? 'bg-isaad-green text-white' : 'bg-white outline outline-2 outline-isaad-green text-gray-700 hover:bg-gray-100'}`}
            onClick={() => onSelect(option)}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

const gradeOptions = [
  { value: '18m', label: '18m' },
  { value: 'PK2', label: 'PK2' },
  { value: 'PK3', label: 'PK3' },
  { value: 'PK4', label: 'PK4' },
  { value: 'Transitional K', label: 'Transitional K' },
  { value: 'K', label: 'K' },
  { value: 'Prm', label: 'Prm' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];

export default function SchoolSearch() {
  const [criteria, setCriteria] = useState({
    studentBody: ['Any'],
    religiousAffiliation: ['Any'],
    specialFocus: ['Any'],
    grades: [],
    zipCode: ''
  });
  const [zipCodeCoordinates, setZipCodeCoordinates] = useState(null);
  const [searchDistance, setSearchDistance] = useState(5);
  const [filteredSchools, setFilteredSchools] = useState(schools);

  useEffect(() => {
    const newFilteredSchools = filterSchools(criteria, schools, zipCodeCoordinates, searchDistance);
    setFilteredSchools(newFilteredSchools);
  }, [criteria, zipCodeCoordinates, searchDistance]);

  const handleChange = (name, value) => {
    setCriteria(prevCriteria => {
      const newCriteria = { ...prevCriteria, [name]: [value] };
      if (value === 'Any') {
        newCriteria[name] = ['Any'];
      } else {
        newCriteria[name] = newCriteria[name].filter(item => item !== 'Any');
      }
      return newCriteria;
    });
  };

  const handleGradeChange = (selectedOptions) => {
    setCriteria(prevCriteria => ({
      ...prevCriteria,
      grades: selectedOptions.map(option => option.value)
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCriteria(prevCriteria => ({ ...prevCriteria, [name]: value }));
  };

  const handleSearch = async () => {
    if (criteria.zipCode) {
      const coordinates = await fetchZipCodeCoordinates(criteria.zipCode);
      setZipCodeCoordinates(coordinates);
    }
  };

  const handleDistanceChange = (increment) => {
    setSearchDistance(prevDistance => Math.max(1, Math.min(30, prevDistance + increment)));
  };

  const searchIcon = L.icon({
    iconUrl: require('../images/searchIcon.png'),
    iconSize: [30, 52],
    iconAnchor: [25, 40],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null
  });

  const studentBodyOptions = getUniqueValues(schools, 'studentBody');
  const religiousAffiliationOptions = getUniqueValues(schools, 'religiousAffiliation');
  const specialFocusOptions = ['Any', 'Montessori', 'International Baccalaureate', 'College Prep', 'Multilingual', 'Learning Difference'];

  return (
    <div className="bg-gray-100 py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/4 w-full bg-white p-4 rounded-lg shadow-md mb-4 lg:mb-0">
            <ButtonGroup
              label="STUDENT BODY"
              options={studentBodyOptions}
              selectedOption={criteria.studentBody}
              onSelect={(selected) => handleChange('studentBody', selected)}
            />
            <ButtonGroup
              label="RELIGIOUS AFFILIATION"
              options={religiousAffiliationOptions}
              selectedOption={criteria.religiousAffiliation}
              onSelect={(selected) => handleChange('religiousAffiliation', selected)}
            />
            <ButtonGroup
              label="SPECIAL FOCUS"
              options={specialFocusOptions}
              selectedOption={criteria.specialFocus}
              onSelect={(selected) => handleChange('specialFocus', selected)}
            />
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-semibold">GRADES</label>
              <Select
                isMulti
                name="grades"
                options={gradeOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={handleGradeChange}
                value={gradeOptions.filter(option => criteria.grades.includes(option.value))}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-semibold">
                <div className="flex">
                  <div className="flex flex-col mr-2">
                    <button onClick={() => handleDistanceChange(1)} className="mb-1 flex items-center">
                      <div className="flex items-center">
                        <span className="mr-2 opacity-0">super secret mi</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 outline outline-1 outline-isaad-green rounded-sm w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 15l-7-7-7 7" />
                        </svg>
                      </div>
                    </button>
                    <div>ZIP CODE (within {searchDistance} miles)</div>
                    <button onClick={() => handleDistanceChange(-1)} className="mt-1 flex items-center">
                      <div className="flex items-center">
                        <span className="mr-2 opacity-0">i love isaad yiiiii</span>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 outline outline-1 outline-isaad-green rounded-sm w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 9l7 7 7-7" />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
              </label>
              <input
                type="text"
                name="zipCode"
                value={criteria.zipCode}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm mt-2"
                placeholder="Enter ZIP code"
              />
            </div>
            <button className="w-full bg-white outline outline-2 outline-isaad-green text-grey-800 py-2 rounded-md text-sm hover:bg-gray-100" onClick={handleSearch}>Search Zip Code</button>
          </div>
          <div className="lg:w-3/4 w-full lg:pl-4">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Filtered Schools</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredSchools.map((school, index) => (
                <Link to={`/school/${school.name}`} key={index} className="hover:shadow-lg transition-shadow duration-300">
                  <School image={school.image} school={school.school} />
                </Link>
              ))}
            
            {filteredSchools.length === 0 && (
              <div className="text-center text-gray-500 mt-6">No schools match the selected criteria.</div>
            )}
            </div>
            <div className="relative z-20 mt-8">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">School Locations</h2>
              <MapContainer center={[32.875, -96.799]} zoom={11} style={{ height: "500px", width: "100%" }}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {filteredSchools.map((school, index) => (
                  <Marker key={index} position={school.coordinates}>
                    <Popup>
                      <strong>{school.school}</strong><br />
                      {school.contactAddress}<br />
                      {school.contactCityStateZip}
                    </Popup>
                  </Marker>
                ))}
                {zipCodeCoordinates && (
                  <Marker icon={searchIcon} position={zipCodeCoordinates}>
                  </Marker>
                )}
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function School({ image, school }) {
  return (
    <div className="bg-white h-20 rounded flex items-center p-4 cursor-pointer hover:bg-gray-200 transition shadow-sm">
      <img src={image} alt={school} className="w-12 h-12 rounded-full mr-4"/>
      <div className="text-sm text-center">{school}</div>
    </div>
  );
}