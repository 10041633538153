//SchoolDetail.js
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import schools from '../Schools';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

export default function SchoolDetail() {
  const { schoolName } = useParams();
  const school = schools.find(s => s.name === schoolName);

  function addCommaAfterCity(schoolContact) {
    const parts = schoolContact.split(' ');
    const city = parts.shift(); // Get the first word which is the city
    const stateZip = parts.join(' '); // Join the rest which is the state and zip
    return `${city}, ${stateZip}`;
}

  if (!school) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div className="text-center">
          <div className="text-3xl font-bold mb-4">School not found</div>
          <Link to="/school-search" className="text-blue-500">Back to School Search</Link>
        </div>
      </div>
    );
  }
  const coords = school.coordinates;
  return (
    <div className="p-6 max-w-7xl mx-auto bg-white rounded-lg shadow-md">
      <Link to="/school-search" className="text-blue-500 mb-4 block">&larr; Back to School Search</Link>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/3 p-4 bg-gray-100 rounded-lg shadow-inner">
          <h2 className="text-2xl font-bold mb-4">Contact Details</h2>
          <p className="mb-2"><strong>Address:</strong> {school.contactAddress}</p>
          <p className="mb-2"><strong>City, State, Zip:</strong> {addCommaAfterCity(school.contactCityStateZip)}</p>
          <p className="mb-2"><strong>Phone:</strong> <a href={`tel:${school.contactPhone}`} className="text-blue-500">{school.contactPhone}</a></p>
          <p className="mb-2"><strong>Email:</strong> <a href={`mailto:${school.contactEmail}`} className="text-blue-500">{school.contactEmail}</a></p>
          <p className="mb-2"><strong>Website:</strong> <a target="_blank" rel="noopener noreferrer" href={school.contactWebsite} className="text-blue-500">{school.contactWebsite}</a></p>
        </div>
        <div className="w-full md:w-2/3 p-4">
          <div className="flex items-center mb-6">
            <img src={school.image} alt={school.school} className="w-24 h-24" />
            <div className="ml-6">
              <h1 className="text-4xl font-bold">{school.school}</h1>
              <a href={school.contactWebsite} target="_blank" rel="noopener noreferrer" className="text-blue-500 text-sm">Visit {school.school}'s Website</a>
            </div>
          </div>
          <p className="text-gray-700 mb-4">{school.description}</p>
          {school.name === 'esd' && (<><p>{school.description2}</p><p className="mb-5"></p></>)}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <p className="text-gray-600"><strong>Grades Offered:</strong> {school.grades.join(', ')}</p>
              <p className="text-gray-600"><strong>Student Body:</strong> {school.studentBody}</p>
              <p className="text-gray-600"><strong>School Type:</strong> {school.schoolType}</p>
            </div>
            <div>
              <p className="text-gray-600"><strong>Religious Affiliation:</strong> {school.religiousAffiliation}</p>
              <p className="text-gray-600"><strong>Special Focus:</strong> {school.specialFocus}</p>
            </div>
          </div>
          <div className="mt-6">
            <h2 className="text-2xl font-bold mb-4">Mission Statement</h2>
            <p className="text-gray-700 mb-4">{school.missionStatement}</p>
          </div>
        </div>
      </div>
      <h2 className="text-3xl font-bold text-gray-800 mb-2 mt-4 flex items-center p-4">
        Map
        <a 
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(school.contactAddress + ' ' + school.contactCityStateZip)}`} 
          target="_blank" 
          rel="noopener noreferrer"
          className="ml-4 text-isaad-blue inline-flex items-center justify-center px-4 py-2 md:px-6 md:py-3 border border-2 hover:border-green-200 border-isaad-green text-sm md:text-base font-medium rounded-full text-isaad-green bg-white transition-colors duration-300 ease"
        >
          Open in Maps
        </a>
      </h2>
      <div className="relative z-20"> {/* Wrapper div with relative positioning and z-index */}
        <MapContainer center={[32.7767, -96.7970]} zoom={10} style={{ height: "500px", width: "100%" }}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker key={school.school} position={coords}>
            <Popup>
              <strong>{school.school}</strong><br />
              {school.contactAddress}<br />
              {school.contactCityStateZip}
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
}
