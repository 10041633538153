import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; 

const Container = styled.div`
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7fafc; /* Light gray background */
  color: #2d3748; /* Dark gray text color */
`;

const ErrorText = styled.h1`
  font-size: 8rem;
  font-weight: bold;
  margin-bottom: 2rem; 
`;

const Message = styled.p`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem; 
`;

const BackLink = styled(Link)`
  background-color: #4299e1; /* Blue button */
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3182ce; /* Darker blue on hover */
  }
`;

const NoPage = () => {
  return (
    <Container>
      <ErrorText>404</ErrorText>
      <Message>Oops! The page you're looking for doesn't exist.</Message>
      <BackLink to="/">Go back to the homepage</BackLink>
    </Container>
  );
};

export default NoPage;