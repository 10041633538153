import { useState } from "react";
import { Outlet, Link } from "react-router-dom";
// import { motion } from "framer-motion";
import logo from '../images/logoNoText.png';
import big from '../images/logoBig.png';
// import embeddings from '../gemini_embeddings.json';

// const { GoogleGenerativeAI } = require("@google/generative-ai");

const Layout = () => {
  const [menu_open, set_menu_open] = useState(false);




  const toggleMenu = () => {
    set_menu_open(!menu_open);
    //setIsSearchOpen(false);
  };

  // const toggleSearch = () => {
  //   setIsSearchOpen(!isSearchOpen);
  // };

  // const handleUserMessage = async (userMessage) => {
  //   const newMessage = { text: userMessage, isUser: true };
  //   setMessages((prevMessages) => [...prevMessages, newMessage, { text: "...", isUser: false }]);
    
  //   const response = await getResponse(userMessage);
  //   setMessages((prevMessages) => {
  //     const updatedMessages = [...prevMessages];
  //     updatedMessages[updatedMessages.length - 1] = { text: response, isUser: false };
  //     return updatedMessages;
  //   });
  // };

  // const genAI = new GoogleGenerativeAI("");

  // const getResponse = async (query) => {
  //   let embeddedDocuments;
  //   async function loadEmbeddings() {
  //     embeddedDocuments = embeddings;
  //   }
  //   let chatHistory = messages.map(msg => msg.isUser ? `User: ${msg.text}` : `Bot: ${msg.text}`).join('\n');
  //   await loadEmbeddings();
  //   async function getEmbedding(text) {
  //     if (chatHistory === "Bot: Welcome! Ask me anything.") {
  //       chatHistory="";
  //     }
  //     if (chatHistory.length > 1000) {
  //       chatHistory = chatHistory.substring(chatHistory.length - 1000);
  //     }
  //     //console.log("Text: " + text)
  //     chatHistory+="\nUser: "+text;
  //     //console.log("ChatHistory:" + chatHistory) 
  //     const embeddingModel = genAI.getGenerativeModel({ model: "embedding-001" });
  //     const result = await embeddingModel.embedContent(text);
  //     return result.embedding;
  //   }
  //   function cosineSimilarity(vecA, vecB) {
  //     vecA = vecA.values || vecA;
  //     vecB = vecB.values || vecB;
    
  //     const dotProduct = vecA.reduce((sum, a, i) => sum + a * vecB[i], 0);
  //     const magnitudeA = Math.sqrt(vecA.reduce((sum, a) => sum + a * a, 0));
  //     const magnitudeB = Math.sqrt(vecB.reduce((sum, b) => sum + b * b, 0));
  //     return dotProduct / (magnitudeA * magnitudeB);
  //   }
    
  //   function findMostRelevantDocument(queryEmbedding) {
  //     let maxSimilarity = -Infinity;
  //     let mostRelevantDoc = null;
    
  //     for (const doc of embeddedDocuments) {
  //       const similarity = cosineSimilarity(queryEmbedding, doc.embedding);
  //       if (similarity > maxSimilarity) {
  //         maxSimilarity = similarity;
  //         mostRelevantDoc = doc;
  //       }
  //     }
    
  //     return mostRelevantDoc;
  //   }
  //   async function generateResponse(prompt) {
  //     const model = genAI.getGenerativeModel({ model: "gemini-pro" });
  //     const result = await model.generateContent(prompt);
  //     const response = await result.response;
  //     return response.text();
  //   }

  //   try {
  //     //console.log("Getting query embedding...");
  //     const queryEmbedding = await getEmbedding(query);
  //     if (!queryEmbedding) {
  //       return "I'm sorry, I couldn't process your query at this time.";
  //     }
  //     //console.log("Finding most relevant document...");
  //     const relevantDoc = findMostRelevantDocument(queryEmbedding);
  //     //console.log(relevantDoc.title);
  //     if (!relevantDoc) {
  //       return "I'm sorry, I couldn't find relevant information to answer your query.";
  //     }

  //     chatHistory = messages.map(msg => msg.isUser ? `User: ${msg.text}` : `Bot: ${msg.text}`).join('\n');
  //     const prompt = `
  //       You are a chat assistant on the website of the The Independent Schools Admission Association of Dallas (ISAAD). With the following members: Alcuin School , Cistercian Preparatory School , Dallas International School , The Episcopal School of Dallas , Good Shepherd Episcopal School , Greenhill School , The Hockaday School , Lakehill Preparatory School , The Lamplighter School , Parish Episcopal School , The Shelton School , St. John’s Episcopal School , St. Mark’s School of Texas , St. Philip’s School and Community Center , Ursuline Academy of Dallas , The Winston School.
  //       Your goal is to be helpful and provide accurate information, and if you can't find the exact answer, provide a general response. BUT DO NOT SAY SOMETHING FALSE
  //       Based on the following information, please answer the user's question.
  //       If the information is not directly relevant, use your general knowledge to provide a helpful response.
  //       The information I'm going to provide you is for your use as backend only, do not refer to getting information to the user in your final output.
  //       Relevant knowledge:
  //       ${relevantDoc.content}
  //       Chat history:
  //       ${chatHistory}
  //       User's question: ${query}
  //       You are in chatting format. Please keep your responses precise and concise. Do not give opinions or personal information, only facts. Also do not refer to info being passed to you. It is already in your knowledge.
  // //       Please provide a concise (chat length, very short. Max 20 words. 40 if it's critical) and informative answer.
  // //       MOST IMPORTANTLY, RESPOND TO THE USER'S QUERY, DO NOT GIVE UNRELATED INFORMATION.
  // //       Response:
  // //     `;

  //     //console.log("Generating response...");
  //     const response = await generateResponse(prompt);
  //     return response;
  //   } catch (error) {
  //     console.error('Error in chat function:', error);
  //     return "I'm sorry, an error occurred while processing your request.";
  //   }
  // };

  const menuItems = [
    { to: "/about", text: "About" },
    { to: "/school-search", text: "School Search" },
    { to: "/events", text: "Events" },
    { to: "/faq", text: "FAQ" },
    { to: "/testing", text: "Testing" },
    { to: "/tuition", text: "Tuition Assistance"},
    { to: "/contact", text: "Contact" },
  ];

  // const LoadingDots = () => (
  //   <div className="flex items-center justify-center space-x-1">
  //     <motion.span
  //       className="h-2 w-2 bg-gray-400 rounded-full"
  //       animate={{ scale: [1, 1.2, 1] }}
  //       transition={{ duration: 0.5, repeat: Infinity }}
  //     />
  //     <motion.span
  //       className="h-2 w-2 bg-gray-400 rounded-full"
  //       animate={{ scale: [1, 1.2, 1] }}
  //       transition={{ duration: 0.5, repeat: Infinity, delay: 0.1 }}
  //     />
  //     <motion.span
  //       className="h-2 w-2 bg-gray-400 rounded-full"
  //       animate={{ scale: [1, 1.2, 1] }}
  //       transition={{ duration: 0.5, repeat: Infinity, delay: 0.2 }}
  //     />
  //   </div>
  // );

  return (
    <div className="flex flex-col h-screen w-screen">
  <nav className="bg-white rounded-sm bg-opacity-40 backdrop-blur-md shadow-md fixed w-full top-0 z-40">
    <div className="container mx-auto px-4 flex justify-between items-center py-4">
      <div className="flex items-center space-x-4 bg-white rounded-xl p-1 px-2 m-2">
        <Link to="/">
          <img src={logo} alt="ISAAD Logo" className="h-16 bg-white lg:hidden" />
          <img src={big} alt="ISAAD Logo" className="hidden bg-white lg:block h-20" />
        </Link>
      </div>
      <div className="hidden md:flex outline outline-2 rounded-xl p-4 opacity-90 bg-white outline-isaad-green items-center space-x-6">
        {menuItems.map((item) => (
          <Link
            key={item.to}
            to={item.to}
            className="text-gray-700 hover:text-green-700 transition duration-300"
          >
            {item.text}
          </Link>
        ))}
      </div>
      <div className="md:hidden">
        <button onClick={toggleMenu} className="text-gray-600 bg-white p-2 rounded-lg outline outline-2 outline-isaad-green opacity-90 hover:outline-4 backdrop-blur-lg focus:outline-none">
          {menu_open ? (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          )}
        </button>
      </div>
    </div>
  </nav>

  <div
    className={`fixed inset-0 bg-gray-600 bg-opacity-60 backdrop-blur-md z-50 transition-opacity duration-300 ${
      menu_open ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
    }`}
    onClick={toggleMenu}
  >
    <div
      className={`fixed inset-y-0 left-0 w-72 bg-white opacity-90 shadow-xl transform transition-transform duration-300 ease-in-out ${
        menu_open ? 'translate-x-0' : '-translate-x-full'
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-end p-4">
        <button onClick={toggleMenu} className="text-gray-700 focus:outline-none">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <ul className="flex flex-col items-start p-4 space-y-4 w-full pb-4">
        {menuItems.map((item) => (
          <li key={item.to} className="w-full">
            <Link
              to={item.to}
              className="w-full text-gray-700 bg-white rounded-lg p-4 text-xl shadow-md hover:bg-green-100 hover:text-green-700 transition duration-300 flex items-center justify-between"
              onClick={toggleMenu}
            >
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>


      <main className="flex-grow lg:pt-26 pt-26.5">
        <Outlet />
      </main>

      {/* Floating Search Button 
      <div className="fixed bottom-6 right-6 z-40 ">
        <button
          onClick={toggleSearch}
          className={`backdrop-blur-md w-14 h-14 rounded-full shadow-lg flex items-center justify-center transition-all duration-300 ${
            isSearchOpen ? 'bg-isaad-green bg-opacity-50' : 'bg-isaad-cool-blue bg-opacity-70'
          }`}
        >
          {isSearchOpen ? (
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          )}
        </button>
      </div>

      {/* Chat Panel (Search Panel modified for chat-like interface) 
      <div
        className={`z-30 fixed bottom-12 md:bottom-24 right-6 md:right-12 lg:right-20 xl:right-24 w-80 md:w-96 bg-white rounded-lg shadow-xl transition-all duration-300 transform ${
          isSearchOpen ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
        }`}
      >
        <div className="p-4 bg-gray-100 rounded-t-lg">
          <h3 className="text-lg font-semibold text-gray-700">Ask Me Anything</h3>
          <p className="text-sm text-gray-500">I will provide you with information about ISAAD.</p>
        </div>
        <div className="p-4 h-72 md:h-72 max-h-72 md:max-h-72 overflow-y-scroll">
          {messages.map((message, index) => (
            <div key={index} className={`flex mb-2 ${message.isUser ? 'justify-end' : 'justify-start'}`}>
              <div
                className={`rounded-lg p-2 ${message.isUser ? 'bg-isaad-cool-blue text-white' : 'bg-gray-200 text-gray-700'}`}
                style={{ maxWidth: '70%' }}
              >
                {message.text === "..." ? <LoadingDots /> : message.text}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="p-4">
          <input
            type="text"
            placeholder="Type your message..."
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleUserMessage(e.target.value);
                e.target.value = "";
              }
            }}
          />
        </div>
      </div>
      */}
      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto p-4">
          
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="useful-links">
              <h2 className="text-xl font-bold mb-2">USEFUL LINKS</h2>
              <hr className="mb-4" />
              <ul>
                <li><a href="https://nais.org/parents/" className="text-grey-600 hover:underline" target="_blank" rel="noopener noreferrer">National Association for Independent Schools (NAIS)</a></li>
                <li><a href="https://www.isasw.org" className="text-grey-600 hover:underline" target="_blank" rel="noopener noreferrer">Independent Schools of the Southwest (ISAS)</a></li>
                <li><a href="https://www.isasw.org/school-directory/faqs-for-prospective-parents" className="text-grey-600 hover:underline" target="_blank" rel="noopener noreferrer">FAQs for Prospective Parents (ISAS)</a></li>
                <li><a href="https://dallascityhall.com/" className="text-grey-600 hover:underline" target="_blank" rel="noopener noreferrer">City of Dallas</a></li>
                <li><a href="https://dfwchild.com/school-guide-2021/" className="text-grey-600 hover:underline" target="_blank" rel="noopener noreferrer">DFWChild.com School Guide</a></li>
                <li><a href="https://www.visitdallas.com/" className="text-grey-600 hover:underline" target="_blank" rel="noopener noreferrer">Visiting Dallas?</a></li>
                <li><a href="https://www.niche.com/" className="text-grey-600 hover:underline" target="_blank" rel="noopener noreferrer">Niche.com</a></li>
              </ul>
            </div>
            <div className="contact">
              <h2 className="text-xl font-bold mb-2">Contact</h2>
              <hr className="mb-4" />
              <p><a href="mailto:isaaddallas@gmail.com" className="block mb-2 hover:underline">isaaddallas@gmail.com</a></p>
              <p><a href="/isaad_printable.pdf" className="block mb-2 hover:underline" target="_blank" rel="noopener noreferrer">Printable PDF</a></p>
              <p><Link to="/resources" className="block mb-2 hover:underline">For Sending Schools: Request Common Teacher Evaluation Form</Link></p>
            </div>
            <p className="text-sm flex items-center justify-center space-x-2">
              <a href="http://dallasprivateschool.org" className="hover:underline" target="_blank" rel="noopener noreferrer">dallasprivateschool.org</a>
              <span>|</span>
              <a href="http://isaadallas.org" className="hover:underline" target="_blank" rel="noopener noreferrer">isaadallas.org</a>
            </p>
            <p className="text-sm text-center">
              © 2024 isaadallas.org | All Rights Reserved
            </p>
          </div>
        </div>
      </footer>


    </div>
  );
};

export default Layout;