import React from 'react';
import schools from '../Schools';

const Contact = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-4">Contact Schools</h1>
      <div className="grid grid-cols-1 gap-6">
        {schools.map((school, index) => (
          <a 
            key={index} 
            href={school.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex items-center space-x-4 p-4 bg-gray-100 rounded shadow hover:bg-gray-200 transition"
          >
            <img src={school.image} alt={school.name} className="h-12 w-12 object-contain" />
            <span className="text-xl font-medium">{school.school}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Contact;