import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import frontgif from "../images/front/home.gif"


import slider1 from '../images/front/slider1.jpg';
import slider2 from '../images/front/slider2.jpg';
import slider3 from '../images/front/slider3.jpg';
import slider4 from '../images/front/slider4.jpg';
import slider5 from '../images/front/slider5.jpg';
import slider6 from '../images/front/slider6.jpg';
import slider7 from '../images/front/slider7.jpg';
import slider8 from '../images/front/slider8.jpg';
import slider9 from '../images/front/slider9.jpg';
import cistercian from '../images/front/cistercian.jpg';
import esd from '../images/front/esd.jpg';
import stphilips from '../images/front/stphilips.jpg';
import stjohns from '../images/front/frontstjohn.JPG';
import frontwinston from '../images/front/frontwinston.jpg';
import frontgreenhill from '../images/front/frontgreenhill.JPG';

import bento1 from '../images/bento/bento1.gif';
import bento2 from '../images/bento/bento2.gif';
import bento3 from '../images/bento/bento3.gif';


import isas from '../images/affiliations/isas.png';
import nais from '../images/affiliations/nais.png';
import tapps from '../images/affiliations/tapps.png';
import ami from '../images/affiliations/ami.png';
import ib from '../images/affiliations/ib.png';
import cb from '../images/affiliations/cb.png';
import mca from '../images/affiliations/mca.png';
import naes from '../images/affiliations/naes.png';
import saes from '../images/affiliations/saes.png';
import spc from '../images/affiliations/spc.png';
import ams from '../images/affiliations/ams.png';
import ibsc from '../images/affiliations/ibsc.png';

const images = [
  slider3, slider2, slider8, esd, slider5, stjohns, frontgreenhill, frontwinston, slider1, slider9, slider7, slider4, cistercian, stphilips, slider6,
];

function shuffle_array(array) {
  return array.sort(() => Math.random() - 0.5);
}

const Home = () => {
  const [shuffled_images, set_shuffled_images] = useState([]);

  useEffect(() => {
    set_shuffled_images(shuffle_array([...images]));
    console.log('images shuffled');
  }, []);

  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    pauseOnHover: false,
    draggable: false,
    swipe: false
  };

  return (
    <>
      <div className="relative h-screen overflow-hidden">
        <div className="absolute inset-0 z-1 pointer-events-none">
          {/* <Slider {...settings}>
            {shuffled_images.map((src, index) => (
              <div key={index}>
                <img
                  src={src}
                  alt={`Slide ${index + 1}`}
                  className="w-full h-screen object-cover"
                />
              </div>
            ))}
          </Slider> */}
          <img src={frontgif} className="h-full w-full object-cover" alt="front gif"></img>
        </div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full bg-gray-900 bg-opacity-40 px-4 py-6 md:px-6">
          <FadeInWhenVisible>
            <h1 className="text-4xl md:text-6xl lg:text-7xl xl:text-8xl text-white font-extrabold text-center leading-tight">
              Dallas Independent Schools
            </h1>
            <p className="text-xl md:text-2xl lg:text-3xl xl:text-4xl text-white mt-4 pb-8 text-center leading-relaxed">
              Shaping Tomorrow's Leaders
            </p>
          </FadeInWhenVisible>
          <FadeInWhenVisible>
            <Link
              to="/school-search"
              className="mt-4 text-lg md:text-xl lg:text-2xl xl:text-3xl text-white py-2 px-4 rounded bg-green-600 hover:bg-green-700 transition duration-300"
            >
              Start Your Search Here
            </Link>
          </FadeInWhenVisible>
        </div>
      </div>

      <div className="h-4 w-full bg-isaad-green" />
      <section className="bg-gray-100 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <FadeInWhenVisible>
            <h2 className="text-3xl font-bold text-gray-900 mb-8">Why Choose ISAAD Schools?</h2>
          </FadeInWhenVisible>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <Link to="/about">
              <FadeInWhenVisible>
                <div className="bg-white p-6 rounded-lg shadow-md outline outline-2 outline-isaad-green hover:outline-4">
                  <h3 className="text-2xl font-bold text-gray-900 mb-4">Experienced Faculty</h3>
                  <p className="text-gray-700">Our member schools boast highly qualified teachers dedicated to student success.</p>
                </div>
              </FadeInWhenVisible>
            </Link>
            <Link to="/about">
              <FadeInWhenVisible>
                <div className="bg-white p-6 rounded-lg shadow-md outline outline-2 outline-isaad-green hover:outline-4">
                  <h3 className="text-2xl font-bold text-gray-900 mb-4">Diverse Programs</h3>
                  <p className="text-gray-700">We offer a wide range of academic and extracurricular programs to suit every student's interests and goals.</p>
                </div>
              </FadeInWhenVisible>
            </Link>
            <Link to="/about">
              <FadeInWhenVisible>
                <div className="bg-white p-6 rounded-lg shadow-md outline outline-2 outline-isaad-green hover:outline-4">
                  <h3 className="text-2xl font-bold text-gray-900 mb-4">Supportive Community</h3>
                  <p className="text-gray-700">Our schools foster a nurturing environment where every student is valued and supported.</p>
                </div>
              </FadeInWhenVisible>
            </Link>
            <Link to="/about">
              <FadeInWhenVisible>
                <div className="bg-white p-6 rounded-lg shadow-md outline outline-2 outline-isaad-green hover:outline-4">
                  <h3 className="text-2xl font-bold text-gray-900 mb-4">Strong Academics</h3>
                  <p className="text-gray-700">ISAAD schools are known for their rigorous academic standards and outstanding college preparation.</p>
                </div>
              </FadeInWhenVisible>
            </Link>
          </div>
        </div>
      </section>
      <div className="h-4 w-full bg-isaad-green" />
      <section className="py-12 bg-isaad-blue text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 text-center">
          <FadeInWhenVisible>
            <h2 className="text-3xl md:text-4xl font-bold mb-8">Testimonials</h2>
          </FadeInWhenVisible>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 grid-rows-3 sm:grid-rows-2 lg:grid-rows-3 gap-4 auto-rows-auto">
            <div className="col-span-1 md:col-span-2 row-span-1 bg-white text-gray-900 p-6 rounded-lg shadow-md flex items-center justify-center outline outline-2 outline-isaad-green hover:outline-4">
              <div>
                <p className="mb-0 text-lg md:text-xl">"ISAAD schools have provided my children with the best education and community support. We couldn't be happier!"</p>
                <h3 className="text-lg md:text-xl xl:text-2xl font-bold">- Parent</h3>
              </div>
            </div>
            <div className="col-span-1 mix-blend-multiply:col-span-1 row-span-2 md:row-span-2 bg-gray-400 rounded-lg shadow-md overflow-hidden hidden md:flex items-center justify-center">
              <img src={bento1} alt="Bento" className="w-full h-full object-cover" />
            </div>
            <div className="col-span-1 md:col-span-2 row-span-1 bg-white text-gray-900 p-6 rounded-lg shadow-md flex items-center justify-center outline outline-2 outline-isaad-green hover:outline-4">
              <div>
                <p className="mb-0 text-lg md:text-xl">"The teachers and staff are amazing, and the variety of programs available has helped me grow in so many ways."</p>
                <h3 className="text-lg md:text-xl xl:text-2xl font-bold">- Student</h3>
              </div>
            </div>
            <div className="col-span-1 md:col-span-2 row-span-1 bg-gray-400 rounded-lg shadow-md overflow-hidden hidden md:flex items-center justify-center">
              <img src={bento2} alt="Bento" className="w-full h-full object-cover" />
            </div>
            <div className="col-span-1 md:col-span-1 row-span-1 bg-white text-gray-900 p-6 rounded-lg shadow-md flex items-center justify-center outline outline-2 outline-isaad-green hover:outline-4">
              <div>
                <p className="mb-0 text-lg md:text-xl">"Joining an ISAAD school has been one of the best decisions for our family's educational journey."</p>
                <h3 className="text-lg md:text-xl xl:text-2xl font-bold">- Parent</h3>
              </div>
            </div>
            <div className="col-span-1 md:col-span-3 row-span-1 h-60 flex bg-gray-400 rounded-lg shadow-md overflow-hidden justify-center">
              <div className="">
                <img src={bento3} alt="Bento" className="w-full h-full object-cover" />
              </div>
              
    </div>

          </div>
        </div>
      </section>


      <div className="bg-white py-24 ">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <h2 className="text-center text-2xl font-semibold leading-8 text-gray-900">Affiliations</h2>
    <div className="mx-auto mt-10">
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 justify-center">
      <img className="max-h-24 w-full object-contain" src={isas} alt="ISAS" />
      <img className="max-h-24 w-full object-contain" src={nais} alt="NAIS" />
      <img className="max-h-24 w-full object-contain" src={tapps} alt="TAPPS" />
      <img className="max-h-24 w-full object-contain" src={ami} alt="AMI" />
      <img className="max-h-24 w-full object-contain" src={ib} alt="IB" />
      <img className="max-h-24 w-full object-contain" src={cb} alt="CB" />
      <img className="max-h-24 w-full object-contain" src={mca} alt="MCA" />
      <img className="max-h-24 w-full object-contain" src={naes} alt="NAES" />
      <img className="max-h-24 w-full object-contain" src={saes} alt="SAES" />
      <img className="max-h-24 w-full object-contain" src={spc} alt="SPC" />
      <img className="max-h-24 w-full object-contain" src={ams} alt="AMS" />
      <img className="max-h-24 w-full object-contain" src={ibsc} alt="IBSC" />
    </div>
    </div>
  </div>
</div>
       

    </>
  );
}

export default Home;

function FadeInWhenVisible({ children }) {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.7 }}
      variants={{
        visible: { opacity: 1, y: 0, scale: 1 },
        hidden: { opacity: 0, y: 20, scale: 0.95 }
      }}
    >
      {children}
    </motion.div>
  );
}
