import React, { useState } from 'react';
import Select from 'react-select';
import schools from '../Schools';

const options = [
  { value: 'alcuin', label: 'Alcuin School' },
  { value: 'cistercian', label: 'Cistercian Preparatory School' },
  { value: 'dallas', label: 'Dallas International School' },
  { value: 'esd', label: 'The Episcopal School of Dallas' },
  { value: 'goodshepherd', label: 'Good Shepherd Episcopal School' },
  { value: 'greenhill', label: 'Greenhill School' },
  { value: 'hockaday', label: 'The Hockaday School' },
  { value: 'lakehill', label: 'Lakehill Preparatory School' },
  { value: 'lamplighter', label: 'The Lamplighter School' },
  { value: 'parish', label: 'Parish Episcopal School' },
  { value: 'shelton', label: 'Shelton School' },
  { value: 'stjohns', label: 'St. John\'s Episcopal School' },
  { value: 'stmarks', label: 'St. Mark\'s School of Texas' },
  { value: 'stphilips', label: 'St. Philip\'s School and Community Center' },
  { value: 'ursuline', label: 'Ursuline Academy of Dallas' },
  { value: 'winston', label: 'The Winston School' }
];

const Testing = () => {
  const [selectedSchool, setSelectedSchool] = useState(null);

  const handleSchoolChange = (selectedOption) => {
    setSelectedSchool(selectedOption);
  };

  const getSchoolInfo = (field) => {
    if (!selectedSchool) return "Please select a school";
    const school = schools.find(s => s.name === selectedSchool.value);
    return school ? school[field] : "Information not available";
  };

  const getSchoolLogo = () => {
    if (!selectedSchool) return null;
    console.log(selectedSchool);
    const school = schools.find(school => school.name === selectedSchool.value);
    console.log(school);
    console.log(school.image);
    return school ? school.image : null;
  };

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-6">Testing</h1>

      <section className="mb-12">
        <p className="text-lg mb-4">Please see each individual school's website for admission testing requirements and deadlines.</p>
      </section>

      <section className="mb-12">
        <div className="flex flex-row justify-between items-center m-4">
          <h2 className="text-3xl font-semibold mb-4">Admission Testing</h2>
          <div className="flex items-center">
            {selectedSchool && (
              <div className="mr-4 w-12 h-12 flex-shrink-0">
                <img
                  src={getSchoolLogo()}
                  alt={`${selectedSchool.value} logo`}
                  className="w-full h-full object-contain"
                />
              </div>
            )}
            <div>
              Info by School: 
              <Select 
                className="min-w-72" 
                options={options} 
                onChange={handleSchoolChange}
                value={selectedSchool}
              />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">Test Name</th>
                <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">Link to Register</th>
                <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">Ages/Grades</th>
                <th className="px-6 py-3 border-b-2 border-gray-200 text-left text-sm leading-4 font-medium text-gray-600 uppercase tracking-wider">School Requires</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-50">
                <td className="px-6 py-4 border-b border-gray-200 text-sm">CATS</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">Cognitive Ability</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">
                  <button className="text-blue-500 underline">
                    <a href="https://www.catstexas.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Register</a>
                  </button>
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{getSchoolInfo('cats')}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{getSchoolInfo('cats2')}</td>
              </tr>
              <tr>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">ISAAD 1st Grade Test</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">Academic Readiness</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">
                  <button className="text-blue-500 underline">
                    <a href="https://isaadallas.admissiontestingsolutions.com/proctored" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Register</a>
                  </button>
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{getSchoolInfo('isaadtest')}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{getSchoolInfo('isaadtest2')}</td>
              </tr>
              <tr className="bg-gray-50">
                <td className="px-6 py-4 border-b border-gray-200 text-sm">ISEE (Independent School Entrance Exam)</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">Achievement Testing</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">
                  <button className="text-blue-500 underline">
                    <a href="https://www.erblearn.org/families/isee-by-erb/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Register</a>
                  </button>
                </td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{getSchoolInfo('isee')}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{getSchoolInfo('isee2')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section>
        <h2 className="text-3xl font-semibold mb-4">ISEE</h2>
        <p className="text-lg mb-4">
        The ISEE (Independent School Entrance Exam) is the standardized assessment required by most independent schools in Dallas as part of the admission process. 
        </p>
        <p className="text-lg mb-4">
        Students may take the ISEE once per testing season.Students may take the ISEE once per testing season—Fall, Winter, and Spring/Summer—for a total of up to three times a school year. To learn more about the test, click <a href="https://www.erblearn.org/families/isee-preparation/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a>.
        </p>
        <p className="text-lg mb-4">
        You can view sample tests, learn more about ISEE practice and test preparation <a href="https://www.erblearn.org/families/isee-preparation/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">here</a>.
        </p>
      </section>
      <section>
        <h2 className="text-3xl font-semibold mb-4">Statement Against Test Prep</h2>
        <p className="text-lg mb-4">
          Cognitive ability testing, such as the tests administered through Collaborative Academic Testing Service, P.A. (CATS), is fundamentally different from achievement testing.
          Cognitive ability testing presents test takers with novel tasks in order to measure intellectual ability while achievement testing measures subject area expertise.
          Normative data for cognitive ability tests are based on the assumption that testers have not been previously exposed to the material.
        </p>
        <p className="text-lg mb-4">
          Prior exposure invalidates test results and is easily detected.
        </p>
        <p className="text-lg mb-4">
          Practicing, rehearsing, preparing, studying or tutoring for a cognitive ability test is not appropriate.
        </p>
        <p className="text-lg mb-4">
          Unlike cognitive ability testing, preparation for achievement testing is appropriate and may be useful.
          To study for an academic achievement test is to prepare for it. A reasonable level of preparation for achievement testing expands general understanding of important content areas.
        </p>
        <p className="text-lg mb-4">
          However, parents should be aware that excessive preparation for achievement testing raises anxiety and negatively affects results.
        </p>
        <p className="text-lg mb-4">
          Acquiring a copy of the test and rehearsing or memorizing the correct answers is never appropriate.
          Preparing for the test is appropriate while rehearsing for the test is not.
        </p>
        <p className="text-lg mb-4">
          At the time of CATS registration, all applicant families will be asked to endorse a statement acknowledging that no cognitive ability tutoring should occur prior to CATS testing and acknowledging the receipt of this document.
          On the day of testing, families will be asked to report any exposure the applicant may have had to the testing materials including any specific cognitive ability test tutoring.
          Indications of prior exposure to testing materials will be reported to the appropriate schools.
        </p>
        <p className="text-lg mb-4">
          While reasonable preparation does not compromise the integrity of achievement testing, tutoring for cognitive ability testing invalidates testing results.
          The Admission Directors of the Independent Schools Admission Association of Dallas seek to make the admission process fair and appropriate for everyone.
          We need accurate testing data to secure good fits between schools and children.
        </p>
      </section>
    </div>
  );
};

export default Testing;