import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import schools from '../Schools';
import L from 'leaflet';
import { Link } from 'react-router-dom';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const schoolCoordinates = {
  'alcuin': [32.915377, -96.79975137721473],
  'cistercian': [32.855745213083644, -96.9219505698687],
  'dallas': [32.917544899999996, -96.80198245808528],
  'esd': [32.88685565, -96.84002062180141],
  'goodshepherd': [32.89982859090909, -96.83844786363636],
  'greenhill': [32.939933894736846, -96.83997485964912],
  'hockaday': [32.907856249999995, -96.82859245],
  'lakehill': [32.82287225, -96.74878059602219],
  'lamplighter': [32.90656, -96.81993479598752],
  'parish': [32.93528325, -96.84328170002547],
  'shelton': [32.987061, -96.803449],
  'stjohns': [32.84550405, -96.70784481736638],
  'stmarks': [32.891268499999995, -96.79994993440096],
  'stphilips': [32.758991449999996, -96.7750149],
  'ursuline': [32.87786595, -96.82359988588235],
  'winston': [32.89552035, -96.81078641844164]
};

export default function About() {
  return (
    <div className="p-6 max-w-4xl mx-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Get to Know ISAAD</h2>
        <p className="text-lg text-gray-700 leading-relaxed mb-2">
          Welcome to ISAAD. The name stands for Independent Schools Admission Association of Dallas. It is a professional association that includes 16 member schools throughout the Dallas area. Membership in the organization is open to schools accredited by <a href="https://www.isasw.org/" className="text-blue-600 hover:underline">ISAS (Independent Schools Association of the Southwest)</a>.
          The member schools represent a complete range of opportunities for students in early education through grade 12.
          The Dallas ISAS schools are committed to providing students in
          pre-kindergarten through high school with a high quality education,
          while helping them develop as unique individuals. The various
          missions, visions, and cultures of our schools are designed to create
          environments that foster intellectual curiosity, stimulate personal
          growth, and encourage critical thinking. We invite you to learn more
          about our schools and hope that you will plan to attend one of our many on-campus admission events.
        </p>
        <p className="text-lg text-gray-700 leading-relaxed mb-6">
        Choosing a school for your child is a personal process, which
        requires you to think about fit, and where your son or daughter would
        thrive. Thank you for exploring the myriad of independent school options
        in Dallas. We are honored that you have taken the time to visit with
        us today, and we thank you for the privilege of your consideration.

        </p>
        <h2 className="text-3xl font-bold text-gray-900 mb-6">The Purpose of ISAAD</h2>
        <ul className="list-disc text-lg pl-5 space-y-2 text-gray-700">
          <li>Assist in making the admission process as user-friendly as possible for our constituents.</li>
          <li>To promote communication among the Directors of Admission.</li>
          <li>To promote private and independent schools in the Dallas area.</li>
          <li>To further the spirit of cooperation among the schools.</li>
          <li>
            To provide a forum for the Directors of Admissions in the Dallas area to become better acquainted with each other, to share common areas of concern, and to discuss the issues that arise in dealing with the admission procedures of member schools.
          </li>
        </ul>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Member Schools</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {schools.map((school, index) => (
            <Link to={`/school/${school.name}`} key={index} className="hover:shadow-lg transition-shadow duration-300">
              <div className="h-full"> {/* Ensure full height */}
                <School image={school.image} school={school.school} className="h-64" /> {/* Adjust height as needed */}
              </div>
            </Link>
          ))}
        </div>
      </div>


      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16 relative z-10"> {/* Added relative positioning and z-index */}
        <h2 className="text-3xl font-bold text-gray-900 mb-6">School Locations</h2>
        <div className="relative z-20"> {/* Wrapper div with relative positioning and z-index */}
          <MapContainer center={[32.7767, -96.7970]} zoom={10} style={{ height: "500px", width: "100%" }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {schools.map((school, index) => {
              const coords = schoolCoordinates[school.name];
              if (!coords) {
                console.error(`Coordinates not found for ${school.name}`);
                return null; // Skip rendering this marker
              }
              return (
                <Marker key={index} position={coords}>
                  <Popup>
                    <strong>{school.school}</strong><br />
                    {school.contactAddress}<br />
                    {school.contactCityStateZip}
                  </Popup>
                </Marker>
              );
            })}

          </MapContainer>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-gray-900 mb-6">Non-Discriminatory Policy</h2>
        <p className="text-lg text-gray-700 leading-relaxed">
          Members of the Independent Schools Association of the Southwest admit students of any race, color, national and ethnic origin to all the rights, privileges, programs, and activities generally accorded or made available to students at the school. They do not discriminate on the basis of race, color, national and ethnic origin in the administration of their educational policies, admission policies, scholarship and loan programs, and athletic and other school-administered programs. Please see each school's website for specific policies.
        </p>
      </div>
    </div>
  );
}

function School({ image, school }) {
  return (
    <div className="bg-white h-36 rounded flex items-center p-4 cursor-pointer shadow-md hover:bg-gray-200 transition"> {/* Adjusted height */}
      <img src={image} alt={school} className="w-16 h-16 rounded-full mr-4" />
      <div className="text-xl font-semibold text-gray-900">{school}</div>
    </div>
  );
}
