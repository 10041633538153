import React from 'react';
import { useState } from 'react';
const Resources = () => {
  const [formData, setFormData] = useState({
    name: '',
    title: '',
    schoolName: '',
    email: '',
    phone: '',
    additionalInfo: '',
    website: '',
    evaluationType: ''
  });

  const [notification, setNotification] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setNotification('Form submitted successfully!');
    setFormData({
      name: '',
      title: '',
      schoolName: '',
      email: '',
      phone: '',
      additionalInfo: '',
      website: '',
      evaluationType: ''
    });
    setTimeout(() => setNotification(''), 3000);
  };
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-3">For Sending Schools: Request Common Teacher Evaluation Form</h1>
            <h3 className="mb-6">If you are an administrator or a teacher for an applicant applying to an ISAAD school, you may complete this form to request the form be sent to you.</h3>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              The Independent School Admission Association of Dallas (ISAAD) adopted common teacher evaluation forms in an attempt to streamline and support the teacher evaluation process for sending schools. This evaluation form will be accepted at the following ISAAD Schools: Alcuin School, Dallas International School, Episcopal School of Dallas, Good Shepherd Episcopal School, Greenhill School, The Hockaday School, Lakehill Preparatory School, The Lamplighter School, Parish Episcopal School, Shelton School, St. John's Episcopal School, St. Mark's School of Texas, and The Winston School.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              Requests for teacher evaluations will come from each receiving school individually. Applicant parents waive their right of access to the teacher evaluation when they submit the request. If a student is applying to more than one ISAAD school, the teacher may complete the common evaluation form one time and submit it via email to multiple schools, rather than completing each school’s individual evaluation form. Each ISAAD school has agreed to treat the common teacher evaluation form as equivalent to their internal teacher evaluation form.
            </p>
            <p className="text-lg text-gray-700 leading-relaxed mb-4">
              Questions about the form, how to submit it, or individual school deadlines should be directed to the admission office at the receiving school.
            </p>
           </div> 
           <div className="object-center flex">
            <iframe title="Request Teacher Evaluation Form" src="https://docs.google.com/forms/d/e/1FAIpQLSfe5t4NRcR590ql8fq3uYW1S3Sgjnrei_u8o-7ZbpcWdNa-ZA/viewform?embedded=true" width="1240" height="2928" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
           </div>
           
        </div>
      </div>
    </div>
  );
};

export default Resources;
