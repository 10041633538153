// src/App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import FAQ from "./pages/FAQ";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import SchoolSearch from "./pages/SchoolSearch";
import Events from "./pages/Events";
import Resources from "./pages/Resources";
import Testing from "./pages/Testing";
import Tuition from "./pages/Tuition";
import Contact from "./pages/Contact";
import SchoolDetail from "./pages/SchoolDetail";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="school-search" element={<SchoolSearch />} />
          <Route path="events" element={<Events />} />
          <Route path="resources" element={<Resources />} />
          <Route path="testing" element={<Testing />} />
          <Route path="tuition" element={<Tuition />} />
          <Route path="contact" element={<Contact />} />
          <Route path="school/:schoolName" element={<SchoolDetail />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}