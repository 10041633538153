import React from 'react';
import { Link } from 'react-router-dom';
import schools from '../Schools'; 

const TuitionAssistance = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Tuition Assistance</h2>
      <p className="text-lg text-gray-700 leading-relaxed mb-6">
        While each of our schools has its own process and philosophy surrounding
        tuition assistance/financial aid, we are all committed to working with
        families, as our budget and policies allow, to help make private school
        a reality for families that demonstrate financial need. Payment plans and
        tuition grants are typical types of assistance offered by our schools. If
        you feel your family cannot afford to pay the full cost of tuition,
        please contact us to find out more about the process and our ability to
        help. Please don’t let cost keep you from applying for admission without
        reaching out!
      </p>

      <h3 className="text-2xl font-bold text-gray-900 mb-4">
        Typical Documents & Process
      </h3>
      <ul className="list-disc text-lg pl-5 space-y-2 text-gray-700 mb-6">
        <li>
          <strong>Third-party FA/TA online application:</strong> You'll find the
          specific application used by each school listed in the table below.
          This comprehensive online process will ask about your financial
          situation to help determine your family's estimated contribution
          towards tuition.
        </li>
        <li>
          <strong>Recent tax documents:</strong> Including prior year W-2s, 1040
          and related business taxes for business owners.
        </li>
        <li>
          <strong>Recent paycheck stubs or business statements:</strong> If
          applicable.
        </li>
      </ul>

      <h3 className="text-2xl font-bold text-gray-900 mb-4">Deadlines</h3>
      <p className="text-lg text-gray-700 leading-relaxed mb-6">
        Most of our schools have a mid-February deadline for the tuition
        assistance/financial aid process for admission families. However, please
        be sure to check with each school for the exact deadline! Our schools
        also work to send the financial aid decision with the admission
        decision, so you have all the information in hand needed to make the
        best decision for your family.
      </p>

      <h3 className="text-2xl font-bold text-gray-900 mb-4">
        School-Specific Information
      </h3>
      <p className="text-lg text-gray-500 leading-relaxed mb-6">
        Tuition Assistance (TA)
      </p>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              School
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              TA Vendor
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              School TA Page
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {schools.map((school) => (
            <tr key={school.name}>
              <td className="px-6 py-4 whitespace-nowrap">
                <Link
                  to={`/school/${school.name}`}
                  className="text-blue-600 hover:underline"
                >
                  {school.school}
                </Link>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <a
                  href={school.vendorLink}
                  className="text-blue-600 hover:underline"
                >
                  {school.vendorLink ? 'Vendor Link' : 'Link Unavailable'} 
                </a>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <a
                  href={school.tuitionLink}
                  className="text-blue-600 hover:underline"
                >
                  {school.tuitionLink ? 'TA Page' : 'Link Unavailable'}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p className="text-lg text-gray-700 leading-relaxed mt-6">
        Please see the links above to find out more about each of our school's
        specific programs. You may find information about qualifying income
        levels, a school’s budget in addition to process and deadline
        information. Please do not hesitate to contact us with questions. We
        want to partner with you as we are able to make our school an option for
        your family.
      </p>
    </div>
  );
};

export default TuitionAssistance;