import alcuin from './images/schools/alcuin.png';
import cistercian from './images/schools/cistercian.png';
import dallas from './images/schools/dallas.png';
import esd from './images/schools/esd.png';
import goodshepherd from './images/schools/goodshepard.png';
import greenhill from './images/schools/greenhill.png';
import hockaday from './images/schools/hockaday.png';
import lakehill from './images/schools/lakehill.png';
import lamplighter from './images/schools/lamplighter.png';
import parish from './images/schools/parish.png';
import shelton from './images/schools/shelton.png';
import stjohns from './images/schools/stjohns.png';
import stmarks from './images/schools/josh.png';
import stphilips from './images/schools/stphilips.png';
import ursuline from './images/schools/ursuline.png';
import winston from './images/schools/winston.png';
import levine from './images/clogo.jpg'
const schools = [
  {
    name: 'alcuin',
    image: alcuin,
    link: 'https://www.alcuinschool.org/admission/visit-alcuin',
    school: 'Alcuin School',
    description: 'Alcuin School is a premier independent Montessori and IB World School providing an exceptional education for students.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: ['Montessori', 'IB', 'College Prep'],
    grades: ['18m', 'PK3', 'PK4', 'K', '1', '2', '3', '4','5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "6144 Churchill Way Dallas",
    contactCityStateZip: "Dallas TX 75230",
    contactPhone: "(972) 239-1745",
    contactEmail: "admissions@alcuinschool.org",
    contactWebsite: "https://www.alcuinschool.org/admission/your-journey-begins-here",
    missionStatement: "Alcuin School inspires its students to reach their highest levels of personal and academic achievement, guides them to become compassionate global citizens, and prepares them to embrace challenges with confidence and innovation by providing the ideal Montessori and International Baccalaureate education.",
    sectionOneHeader: "Placeholder section one header for Alcuin School.",
    sectionOneContents: "Placeholder section one contents for Alcuin School.",
    sectionTwoHeader: "Placeholder section two header for Alcuin School.",
    sectionTwoContents: "Placeholder section two contents for Alcuin School.",
    message: "Placeholder message for Alcuin School.",
    coordinates: [32.89552035, -96.81078641844164],
    tuitionLink: "https://www.alcuinschool.org/admission/tuition",
    vendorLink: "https://www.ismfast.com/FastPage.php?id=3074e923b192722398ef30f1c5103292",
    cats: "n/a",
    cats2: "No CATS testing information provided",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "2nd - 11th grade",
    isee2: "ISEE required for 2nd through 11th grade"
  },
  {
    name: 'cistercian',
    image: cistercian,
    link: 'https://school.cistercian.org/admissions/from-the-director/',
    school: 'Cistercian Preparatory School',
    description: 'Cistercian is an independent Catholic preparatory school near Dallas, Texas, educating boys in grades 5-12 in an all-honors curriculum.',
    studentBody: 'All-boys',
    schoolType: [],
    religiousAffiliation: 'Catholic',
    specialFocus: ['College Prep'],
    grades: ['5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "3660 Cistercian Rd",
    contactCityStateZip: "Irving TX 75039",
    contactPhone: "(469) 499-5400",
    contactEmail: "admissions@cistercian.org",
    contactWebsite: "https://www.cistercian.org/",
    missionStatement: "Cistercian Preparatory School is rooted in the life and spirit of Catholic monasticism. It offers talented and motivated young men challenging programs within a supportive community, allowing them to develop their strengths and face their weaknesses in preparation for college and life beyond, all for the good of the whole person in service to God and neighbor.",
    coordinates: [32.855745213083644, -96.9219505698687],
    tuitionLink: "https://school.cistercian.org/admissions/financial-aid/",
    vendorLink: "https://www.solutionsbysss.com/parents/",
    cats: "n/a",
    cats2: "n/a",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "n/a",
    isee2: "n/a"
  },
  {
    name: 'dallas',
    image: dallas,
    link: 'https://www.dallasinternationalschool.org/admissions',
    school: 'Dallas International School',
    description: 'Dallas International School offers a unique, multicultural education.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: [],
    grades: ['Pre-K', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "6039 Churchill Way",
    contactCityStateZip: "Dallas TX 75230",
    contactPhone: "(972) 991-6379",
    contactEmail: "admissions@dallasinternationalschool.org",
    contactWebsite: "https://www.dallasinternationalschool.org/",
    missionStatement: "DIS mission as a community is to inspire students to become multilingual global citizens who pursue a lifelong journey of discovery and action where the quest for deep academic knowledge and an appreciation of world cultures contribute to the advancement of humanity.",
    coordinates: [32.917544899999996, -96.80198245808528],
    tuitionLink: "https://www.dallasinternationalschool.org/admissions/tuition-and-financialaid",
    vendorLink: "https://studentfinancialaid.blackbaud.school/#!/login",
    cats: "No information provided",
    cats2: "No information provided",
    isaadtest: "No information provided",
    isaadtest2: "No information provided",
    isee: "No information provided",
    isee2: "No information provided"
  },
  {
    name: 'esd',
    image: esd,
    link: 'https://www.esdallas.org/admission',
    school: 'The Episcopal School of Dallas',
    description: 'Our Founding Tenets of religio, daily worship; eruditio, scholarship; and disciplina, training within a community of learners, have continued to direct our mission to ignite a life of purpose in each student through the development of an educated conscience. In combination with outdoor education and service learning, these elements are essential to the ESD experience, allowing teachers to shepherd children through lessons that enable them to overcome trepidation, develop resilience, and embrace stillness.',
    description2: ' Though we have grown to serve more than 1,100 students aged 3 to 12th grades, we continue to know and love each child. We optimally challenge all students, capitalizing on their passions or interests, making our most advanced curriculum accessible to all. We shape our curriculum using the Hallmarks of an ESD Education: academic excellence and rigor, a strong sense of community, understanding of self, and a faith-informed foundation of values.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Episcopal',
    specialFocus: ['College Prep'],
    grades: ['PK3', 'PK4', 'K', 'Prm', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "4100 Merrell Road",
    contactCityStateZip: "Dallas TX 75229",
    contactPhone: "(214) 358-4368",
    contactEmail: "admission@esdallas.org",
    contactWebsite: "http://www.esdallas.org",
    missionStatement: "Igniting a life of purpose through the development of an educated conscience.",
    coordinates: [32.88685565, -96.84002062180141],
    tuitionLink: "https://www.esdallas.org/admission/tuition",
    vendorLink: "https://www.solutionsbysss.com/parents/",
    cats: "PK4 - 4th grade",
    cats2: "CATS required for PK4 through 4th grade",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "5th - 10th grade",
    isee2: "ISEE required for 5th through 9th grade; PSAT or ISEE for 10th grade, PSAT for 11th grade"
  },
  { 
    name: 'goodshepherd', 
    image: goodshepherd, 
    link: 'https://www.gsesdallas.org/admission/welcome', 
    school: 'Good Shepherd Episcopal School', 
    description: 'Good Shepherd Episcopal School offers a vibrant and nurturing community where students thrive. The school\'s commitment to academic excellence, character development, and spiritual growth provides a strong foundation for lifelong learning. Good Shepherd\'s curriculum emphasizes critical thinking, creativity, and collaboration, with a focus on individualized instruction. Students benefit from a wide range of extracurricular activities, including arts, sports, and service projects. The school\'s inclusive and supportive environment fosters a sense of belonging and encourages students to reach their full potential.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Episcopal',
    specialFocus: [],
    grades: ['PK4', 'K', 'Prm', '1', '2', '3', '4', '5', '6', '7', '8'],
    contactAddress: "11110 Midway Road",
    contactCityStateZip: "Dallas TX 75229",
    contactPhone: " (214) 357-1610",
    contactEmail: "admissions@gsesdallas.org",
    contactWebsite: "http://www.gsesdallas.org",
    missionStatement: "From their first day of prekindergarten through their eighth grade graduation, we provide an environment where your children can realize the best versions of themselves. GSES students develop independence through structured intellectual exploration. They practice empathy and grow spiritually by serving others. And ultimately, they leave Good Shepherd equipped and emboldened to make a meaningful impact on the world around them.",
    coordinates: [32.89982859090909, -96.83844786363636],
    tuitionLink: "https://www.gsesdallas.org/admissions-link/tuition-assistance",
    vendorLink: "https://www.solutionsbysss.com/parents/",
    cats: "Kinder - 1st grade",
    cats2: "CATS required for Kinder; CATS and ATS for 1st grade",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "2nd - 8th grade",
    isee2: "ISEE required for 2nd through 8th grade"
  },
  { 
    name: 'greenhill', 
    image: greenhill, 
    link: 'https://www.greenhill.org/admission/meet-our-team', 
    school: 'Greenhill School', 
    description: 'Greenhill School fosters a diverse and inclusive community dedicated to excellence in education. The school\'s challenging curriculum, which includes Advanced Placement (AP) and honors courses, prepares students for success in college and beyond. Greenhill\'s commitment to the arts, athletics, and service provides students with a well-rounded educational experience. The school\'s supportive environment encourages students to explore their interests, develop their talents, and build meaningful relationships. Greenhill graduates are confident, compassionate, and well-prepared to make a positive impact in the world.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: ['College Prep'],
    grades: ['PK4', 'K', 'Prm', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "4141 Spring Valley Road",
    contactCityStateZip: "Addison TX 75001",
    contactPhone: " (972) 628-5910",
    contactEmail: "admission@greenhill.org",
    contactWebsite: "http://www.greenhill.org",
    missionStatement: "Greenhill’s tradition of vigorous self-inquiry extends to all aspects of the school. As our world changes, so, too, must the language we use to articulate the aims of a Greenhill education, our shared values, and our expectations of one another. As a part of our recent strategic planning process, we clarified our mission and vision statements and core values to better describe our aspirations for our school. The results of that work honor our past and allow us to face the future with greater clarity and focus.In a diverse and inclusive community, Greenhill prepares and inspires students to lead authentic, purposeful lives.",
    coordinates: [32.939933894736846, -96.83997485964912],
    tuitionLink: "https://www.greenhill.org/admission/financial-aid",
    vendorLink: "https://www.solutionsbysss.com/parents/",
    cats: "PK4 - 1st grade",
    cats2: "CATS required for PK4 through 1st grade",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "2nd - 11th grade",
    isee2: "ISEE required for 2nd through 11th grade"
  },
  { 
    name: 'hockaday', 
    image: hockaday, 
    link: 'https://www.hockaday.org/admission/discover-hockaday', 
    school: 'The Hockaday School', 
    description: 'The Hockaday School is an independent, college-preparatory day and boarding school for girls. The school\'s rigorous academic program, combined with a strong emphasis on character development, prepares students for success in college and beyond. Hockaday offers a wide range of extracurricular activities, including athletics, arts, and community service. The school\'s supportive environment encourages students to explore their interests, develop their talents, and build meaningful relationships. Hockaday graduates are confident, compassionate, and well-prepared to make a positive impact in the world.',
    studentBody: 'All-girls',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: ['College Prep'],
    grades: ['PK4', 'K', 'Prm', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "11600 Welch Road",
    contactCityStateZip: "Dallas TX 75229",
    contactPhone: " (214) 363-6311",
    contactEmail: "admission@hockaday.org",
    contactWebsite: "http://www.hockaday.org",
    missionStatement: "Believing in the limitless potential of girls, Hockaday develops resilient, confident women who are educated and inspired to lead lives of purpose and impact.",
    coordinates: [32.907856249999995, -96.82859245],
    tuitionLink: "https://www.hockaday.org/admission/affording",
    vendorLink: "https://www.solutionsbysss.com/parents/",
    cats: "PK4 - 1st grade",
    cats2: "CATS required for PK4, Kinder; CATS and ATS for 1st grade",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "2nd - 11th grade",
    isee2: "ISEE required for 2nd through 11th grade"
  },
  { 
    name: 'lakehill', 
    image: lakehill, 
    link: 'https://www.lakehillprep.org/admission', 
    school: 'Lakehill Preparatory School', 
    description: 'Lakehill Preparatory School offers a nurturing and challenging academic environment. The school\'s rigorous curriculum prepares students for success in college and beyond. Lakehill\'s commitment to the arts, athletics, and community service provides students with a well-rounded educational experience. The school\'s supportive environment encourages students to explore their interests, develop their talents, and build meaningful relationships. Lakehill graduates are confident, compassionate, and well-prepared to make a positive impact in the world.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: ['Collge Prep'],
    grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "2720 Hillside Drive",
    contactCityStateZip: "Dallas TX 75214",
    contactPhone: " (214) 826-2931",
    contactEmail: "admission@lakehillprep.org",
    contactWebsite: "http://www.lakehillprep.org",
    missionStatement: "Lakehill Preparatory School guides motivated students to learn with curiosity, lead with confidence, and serve with compassion. Our challenging and nurturing educational community inspires students to become collaborative problem-solvers who live with integrity.",
    coordinates: [32.82287225, -96.74878059602219],
    tuitionLink: "https://www.lakehillprep.org/admissions/",
    vendorLink: "https://www.solutionsbysss.com/parents/" ,
    cats: "n/a",
    cats2: "No CATS testing information provided",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "2nd - 11th grade",
    isee2: "ISEE required for 2nd through 10th grade; PSAT or ISEE for 10th and 11th grade"
  },
  { 
    name: 'lamplighter', 
    image: lamplighter, 
    link: 'https://www.thelamplighterschool.org/apply/the-lamplighter-difference', 
    school: 'The Lamplighter School', 
    description: 'The Lamplighter School offers an innovative and nurturing environment for young learners. The school\'s curriculum emphasizes hands-on learning, critical thinking, and creativity. Lamplighter\'s commitment to the arts, athletics, and character education provides students with a well-rounded educational experience. The school\'s supportive environment encourages students to explore their interests, develop their talents, and build meaningful relationships. Lamplighter graduates are confident, compassionate, and well-prepared for future academic success.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: [],
    grades: ['PK3','PK4', 'K', 'Prm', '1', '2', '3', '4'],
    contactAddress: "11611 Inwood Road",
    contactCityStateZip: "Dallas TX 75229",
    contactPhone: " (214) 369-9201",
    contactEmail: "admission@thelamplighterschool.org",
    contactWebsite: "http://www.thelamplighterschool.org",
    missionStatement: "Dedicated to igniting the potential of each child, Lamplighter engages children in the joy of learning through intellectual discovery in a creative, inclusive, and collaborative environment.",
    coordinates: [32.90656, -96.81993479598752],
    tuitionLink: "https://www.thelamplighterschool.org/apply/tuition-financial-aid",
    vendorLink: "https://studentfinancialaid.blackbaud.school/#!/login",
    cats: "PK4 - 1st grade",
    cats2: "CATS required for PK4, Kinder, Primer; CATS and ATS for 1st grade",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "2nd - 4th grade",
    isee2: "ISEE required for 2nd through 4th grade"
  },
  { 
    name: 'parish', 
    image: parish, 
    link: 'https://www.parish.org/admissions', 
    school: 'Parish Episcopal School', 
    description: 'Parish Episcopal School provides a dynamic and engaging educational experience. The school\'s challenging curriculum, which includes Advanced Placement (AP) and honors courses, prepares students for success in college and beyond. Parish\'s commitment to the arts, athletics, and service provides students with a well-rounded educational experience. The school\'s supportive environment encourages students to explore their interests, develop their talents, and build meaningful relationships. Parish graduates are confident, compassionate, and well-prepared to make a positive impact in the world.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Episcopal',
    specialFocus: ['College Prep'], 
    grades: ['PK3', 'PK4', 'K', 'Prm', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "4101 Sigma Road",
    contactCityStateZip: "Dallas TX 75244",
    contactPhone: " (972) 239-8011",
    contactEmail: "admission@parish.org",
    contactWebsite: "http://www.parish.org",
    missionStatement: "Inspired by our values of Wisdom, Honor and Service, our inclusive Episcopal community guides young people to become creative learners and bold leaders prepared to impact our complex global society.",
    coordinates: [32.93528325, -96.84328170002547],
    tuitionLink: "https://www.parish.org/admission/tuition-assistance",
    vendorLink: "https://app.clarityapp.com/sign-up",
    cats: "Kinder, 1st grade",
    cats2: "CATS required for Kinder; CATS and ISAAD for 1st grade",
    isaadtest: "1st grade",
    isaadtest2: "ISAAD test required along with CATS for 1st grade",
    isee: "2nd - 11th grade",
    isee2: "ISEE required for 2nd through 10th grade; PSAT or ISEE for 10th and 11th grade"
  },
  { 
    name: 'shelton', 
    image: shelton, 
    link: 'https://www.shelton.org/admission/welcome', 
    school: 'Shelton School', 
    description: 'Shelton School provides a supportive and nurturing environment for students with learning differences. The school\'s specialized curriculum and individualized instruction help students develop their strengths and overcome challenges. Shelton\'s commitment to the arts, athletics, and community service provides students with a well-rounded educational experience. The school\'s supportive environment encourages students to explore their interests, develop their talents, and build meaningful relationships. Shelton graduates are confident, compassionate, and well-prepared for future academic success.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: ['Montessori', 'Learning Difference', 'College Prep'],
    grades: ['PK3', 'PK4', 'Transitional K', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "17301 Preston Road",
    contactCityStateZip: "Dallas TX 75252",
    contactPhone: " (972) 774-1772",
    contactEmail: "admissions@shelton.org",
    contactWebsite: "http://www.shelton.org",
    missionStatement: "Shelton is on a mission. We are a school and resource center dedicated to making a difference by serving and empowering the lives of students who learn differently. Because Not All Great Minds Think Alike.",
    coordinates:  [32.987061, -96.803449],
    tuitionLink: "https://www.shelton.org/admission/tuition-assistance",
    vendorLink: "https://online.factsmgt.com/aid",
    cats: "n/a",
    cats2: "No CATS testing information provided",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "n/a",
    isee2: "Diagnostic testing within the last 3 years required for Pre-K through 11th grade"
  },
  { 
    name: 'stjohns', 
    image: stjohns, 
    link: 'https://www.stjohnsschool.org/admission', 
    school: 'St. John’s Episcopal School', 
    description: 'St. John’s Episcopal School nurtures the whole child in a supportive and challenging environment. The school\'s commitment to academic excellence, character development, and spiritual growth provides a strong foundation for lifelong learning. St. John\'s curriculum emphasizes critical thinking, creativity, and collaboration, with a focus on individualized instruction. Students benefit from a wide range of extracurricular activities, including arts, sports, and service projects. The school\'s inclusive and supportive environment fosters a sense of belonging and encourages students to reach their full potential.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Episcopal',
    specialFocus: ['College Prep'],
    grades: ['PK3', 'PK4', 'Transitional K', 'K', '1', '2', '3', '4', '5', '6', '7', '8'],
    contactAddress: "848 Harter Road",
    contactCityStateZip: "Dallas TX 75218",
    contactPhone: "(214) 814-4137",
    contactEmail: "admission@stjohnsschool.org",
    contactWebsite: "http://www.stjohnsschool.org",
    missionStatement: "We are dedicated to a program of academic excellence designed to train the mind, strengthen the character, and enrich the spirit of each student in a Christian environment.",
    coordinates:  [32.84550405, -96.70784481736638],
    tuitionLink: "https://www.stjohnsschool.org/admission/tuition-and-tuition-assistance",
    vendorLink: "https://online.factsmgt.com/aid",
    cats: "PK4 - 1st grade",
    cats2: "CATS required for PK4, PK3, Kinder, and 1st grade",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "2nd - 8th grade",
    isee2: "ISEE required for 2nd through 8th grade"
    },
  { 
    name: 'stmarks', 
    image: stmarks, 
    link: 'https://www.smtexas.org/admission/welcome', 
    school: 'St. Mark’s School of Texas', 
    description: 'St. Mark’s School of Texas is a private, nonsectarian college-preparatory boys’ day school for students in grades 1 through 12, located in Dallas, Texas. St. Mark’s aims to prepare young men to assume leadership and responsibility in a competitive and changing world.',
    studentBody: 'All-boys',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: ['College Prep'],
    grades: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "10600 Preston Road",
    contactCityStateZip: "Dallas TX 75230",
    contactPhone: "(214) 346-8700",
    contactEmail: "admission@smtexas.org",
    contactWebsite: "https://www.smtexas.org",
    missionStatement: "St. Mark’s aims to prepare young men for assuming leadership and responsibility in a competitive and changing world. To this end, the school professes and upholds certain values. These values include the discipline of postponing immediate gratification in the interest of earning eventual, hard-won satisfaction; the responsibility of defending one’s own ideas, of respecting the views of others, and of accepting the consequences for one’s own actions; and an appreciation for the lively connection between knowledge and responsibility, privilege and the obligation to serve.",
    coordinates: [32.891268499999995, -96.79994993440096],
    tuitionLink: "https://www.smtexas.org/admission/tuition-and-financial-aid",
    vendorLink: "https://app.clarityapp.com/sign-up",
    cats: "1st - 4th grade",
    cats2: "CATS and ATS for 1st grade; CATS and ISEE for 2nd through 4th grade",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "2nd - 11th grade",
    isee2: "ISEE required for 2nd through 11th grade; CATS also required for 2nd through 4th grade"
    },
  { 
    name: 'stphilips', 
    image: stphilips, 
    link: 'https://www.stphilips1600.org/admissions/', 
    school: 'St. Philip’s School and Community Center', 
    description: 'St. Philip’s School and Community Center offers a nurturing environment that fosters academic excellence and community engagement. The school\'s commitment to academic excellence, character development, and community service provides a strong foundation for lifelong learning. St. Philip\'s curriculum emphasizes critical thinking, creativity, and collaboration, with a focus on individualized instruction. Students benefit from a wide range of extracurricular activities, including arts, sports, and service projects. The school\'s inclusive and supportive environment fosters a sense of belonging and encourages students to reach their full potential.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Episcopal',
    specialFocus: [],
    grades: ['PK2', 'PK3', 'PK4', 'K', 'Prm', '1', '2', '3', '4', '5', '6', '7', '8'],
    contactAddress: "1600 Pennsylvania Ave.",
    contactCityStateZip: "Dallas TX 75215",
    contactPhone: "(214) 421-5221",
    contactEmail: "admission@stphilips1600.org",
    contactWebsite: "http://www.stphilips1600.org",
    missionStatement: "To provide an unparalleled education and compatible community services through a faith-based experience, with emphasis on serving low and moderate income families.",
    coordinates:  [32.758991449999996, -96.7750149],
    tuitionLink: "https://www.stphilips1600.org/admissions/tuitionassistance.cfm",
    vendorLink: "https://app.clarityapp.com/sign-up",
    cats: "n/a",
    cats2: "No CATS testing information provided",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "n/a",
    isee2: "MAP testing required for 1st through 8th grade"
    },
  { 
    name: 'ursuline', 
    image: ursuline, 
    link: 'https://www.ursulinedallas.org/admissions', 
    school: 'Ursuline Academy of Dallas', 
    description: 'Founded in 1874, Ursuline Academy of Dallas is an independent, Catholic college-preparatory school for young women sponsored by the Ursuline Sisters. The school\'s rigorous academic program, combined with a strong emphasis on spiritual growth and character development, prepares students for success in college and beyond. Ursuline offers a wide range of extracurricular activities, including athletics, arts, and community service. The school\'s supportive environment encourages students to explore their interests, develop their talents, and build meaningful relationships. Ursuline graduates are confident, compassionate, and well-prepared to make a positive impact in the world.',
    studentBody: 'All-girls',
    schoolType: [],
    religiousAffiliation: 'Catholic',
    specialFocus: ['College Prep'],
    grades: ['9', '10', '11', '12'],
    contactAddress: "4900 Walnut Hill Lane",
    contactCityStateZip: "Dallas TX 75229",
    contactPhone: " (469) 232-1800",
    contactEmail: "admissions@ursulinedallas.org",
    contactWebsite: "http://www.ursulinedallas.org",
    missionStatement: "The mission of Ursuline Academy is the total development of the individual student through spiritual formation, intellectual growth, service to others, and building of community. Ursuline Academy educates young women for leadership in a global society.",
    coordinates: [32.87786595, -96.82359988588235],
    tuitionLink: "https://www.ursulinedallas.org/admissions/tuition-financial-aid",
    vendorLink: "https://online.factsmgt.com/aid",
    cats: "n/a",
    cats2: "No CATS testing information provided",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "9th - 11th grade",
    isee2: "ISEE required for 9th grade; PSAT or ISEE for 10th and 11th grade"
  },
  { 
    name: 'winston', 
    image: winston, 
    link: 'https://www.winston-school.org/admission/', 
    school: 'The Winston School', 
    description: 'The Winston School provides a supportive environment for students with learning differences to succeed. The school\'s comprehensive program includes a rigorous academic curriculum, specialized support services, and a strong emphasis on character development. Winston\'s individualized approach ensures that each student receives the support and resources they need to succeed. The school also offers a wide range of extracurricular activities, including arts, sports, and community service, providing a well-rounded educational experience. Winston graduates are confident, capable, and prepared for the challenges of higher education and beyond.',
    studentBody: 'Co-ed',
    schoolType: [],
    religiousAffiliation: 'Non-Sectarian',
    specialFocus: ['College Prep', 'Learning Difference'],
    grades: ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    contactAddress: "5707 Royal Lane",
    contactCityStateZip: "Dallas TX 75229",
    contactPhone: "(214) 691-6950",
    contactEmail: "info@winston-school.org",
    contactWebsite: "http://www.winston-school.org",
    missionStatement: "The Winston School is a small college preparatory school designed to maximize the potential of bright students who learn differently®. Through individualized learning strategies our students are empowered to meet confidently the challenges of tomorrow.",
    coordinates: [32.89552035, -96.81078641844164],
    tuitionLink: "https://www.winston-school.org/admission/affording-winston",
    vendorLink: "https://ismfast.com/FastPage.php?id=1a80645ad09ac68fd8640e6130718433",
    cats: "n/a",
    cats2: "No CATS testing information provided",
    isaadtest: "n/a",
    isaadtest2: "n/a",
    isee: "n/a",
    isee2: "Diagnostic testing within the last 3 years required for Kinder through 11th grade"
    },
];

export default schools;